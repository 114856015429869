<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field
        v-model="phone"
        name="phone"
        label="技师手机号"
        placeholder="请输入"
      />
      <van-field
        v-model="name"
        name="name"
        label="技师名称"
        disabled
        v-if="bind"
      />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        {{ bind ? '解绑' : '绑定' }}
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const state = reactive({
      productId: useRoute.query.productId,
      row: {},
      bind: false,
      storeId: Cookies.get('storeId'),
      phone: '',
      name: ''
    })
    const onSubmit = (values) => {
      Toast.loading({ duration: 0, forbidClick: true })
      console.log('submit', values)
      if (state.bind) {
        post('/product.bindTechnician', {
          productId: state.productId,
          bind: false
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            init()
          } else {
            Toast(res.msg)
          }
        })
      } else {
        post('/staff.get', {
          phone: state.phone,
          storeId: state.storeId
        }).then(res => {
          if (res.code === 0) {
            post('/product.bindTechnician', {
              productId: state.productId,
              technicianId: res.data.staffId,
              bind: true
            }).then(res => {
              if (res.code === 0) {
                Toast.success()
                init()
              } else {
                Toast(res.msg)
              }
            })
          } else {
            Toast(res.msg)
          }
        })
      }
    }
    const init = () => {
      console.log(123)
      post('/product.get', {
        productId: state.productId
      }).then(res => {
        if (res.data.technician) {
          state.phone = res.data.technician.user.phone
          state.name = res.data.technician.name
          state.bind = true
        } else {
          state.phone = ''
          state.name = ''
          state.bind = false
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit
    }
  }
}
</script>
